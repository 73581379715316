form {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.form-section {
  align-items: flex-start;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  padding: 20px;
  max-width: 800px;
}

.retention-horizontal {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  width: 100%;
}

h3 {
  align-self: flex-start;
}

.header {
  display: flex;
}

.consent {
  display: flex;
  align-items: flex-start;
}

.consent-text {
  width: 100%;
  font-size: 0.9rem;
}

.submit-button {
  text-align: center;
  background-color: white;
  border: none;
  border-radius: 50px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  color: #0095CF;
  cursor: pointer;
  font-weight: 400;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 150px;
  transition: all .2s ease-in-out;
  width: 300px;
}

.submit-button:hover {
  transform: scale(1.1);
}

.no-access {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  color: #0095CF;
  cursor: pointer;
  font-weight: 400;
  height: 150px;
  margin-top: 150px;
  margin-bottom: 150px;
  transition: all .2s ease-in-out;
  width: 300px;
}

