.momentum-header {
  display: flex;
  width: 90vw;
  justify-content: flex-start;
  padding: 20px;
  align-items: flex-start;
}

.company-logo {
  width: 200px;
}