header {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-family: "GothamBold";
  font-size: large;
  height: 5vh;
  justify-content: center;
  width: 100vw;
}
