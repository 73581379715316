.result-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 60vh;
  margin: 50px;
  padding: 30px;
}