body {
  background-image: linear-gradient(90deg, #369, #369);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}



@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"), url("./fonts/Gotham-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GothamBlack";
  src: local("GothamBlack"), url("./fonts/Gotham-Black.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"), url("./fonts/Gotham-Book.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"), url("./fonts/Gotham-Medium.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "LatoBold";
  src: local("LatoBold"), url("./fonts/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "LatoRegular";
  src: local("LatoRegular"), url("./fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
}

code {
  font-family: "LatoRegular"
}