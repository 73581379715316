.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: large;
  font-family: "LatoRegular";
  align-items: center;
  max-width: 100vw;
}

.shadow {
  box-shadow: 0 2px 5px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border: 1px solid #00000013;
  border-radius: 5px;
  height: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
}

h1 {
  color: #0095CF;
  font-size: large;
}

label {
  font-size: large;
  text-align: left;
}

header {
  font-size: large;
}