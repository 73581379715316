.tile {
  background-color: #EFEFEF;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95vw;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.table {
  display: grid;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  width: 95%;
  overflow: hidden;
}

.table-row {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  font-size: 14px;
  width: 100%;
  grid-gap: 1px;
  color: black;
}

.table-cell {
  padding: 8px;
  display: flex;
  flex-direction: row;
}

.table-notes {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.notes-header {
  font-size: 14px;
  font-weight: bold;
}


.notes {
  font-size: 12px;
  text-align: left;
  height: 70px;
  overflow: scroll;
  overflow-x: hidden;
}

.cell-header {
  font-weight: bold;
  margin-right: 5px;
  word-wrap: break-word;
}

.white {
  border-bottom: 1px solid black;
  background-color: white;
}

.grey {
  border-bottom: 1px solid black;
  background-color: #F7F7F7;
}