.photos {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr) );
  width: 100%;
}

.inspection-form {
  margin-top: 0;
  width: 90vw;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.photos-container {
  width: 100%;
}

.submit-button {
  font-size: medium;
}

h2 {
  color: white;
}